<template>
    <main>
<!--        <div class="header">-->
<!--            <h1 class="heading">{{$t('settings.title')}}</h1>-->
<!--            <v-btn class="btn_text" -->
<!--                text -->
<!--                small -->
<!--                color="lightgrey" -->
<!--                @click="logout"-->
<!--            >-->
<!--                <v-icon>mdi-exit-to-app</v-icon>-->
<!--                {{$t('settings.logout')}}-->
<!--            </v-btn>-->
<!--        </div>-->
      <h1 class="heading">{{$t('settings["Личные данные"]')}}</h1>
        <div class="settings" style="margin-top:20px;" v-if="!isMobile">
            <div class="settings__info">
                <div class="form form_row">
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <v-text-field solo outlined flat class="large_50"
                                :label="$t('app[\'Ввести\']')"
                                autocomplete="new-password"
                                :rules="nameRules"
                                v-model="profile.name"
                            ></v-text-field>
                        </div>
                    </div>
                  <div class="field">
                    <label class="label">Last Name</label>
                    <div class="control">
                      <v-text-field solo outlined flat
                                    label="Last Name" class="large_50"
                                    autocomplete="new-password"
                                    :rules="lastNameRules"
                                    v-model="profile.last_name"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Phone</label>
                    <div class="control">
                      <v-text-field solo outlined flat class="large_50"
                                    v-model="profile.phone"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                      <v-text-field solo outlined flat class="large_50"
                                    :rules="emailRules"
                                    v-model="profile.email"
                      ></v-text-field>
                    </div>
                  </div>
<!--                    <div class="settings__contacts">-->
<!--                        <div class="field" style="align-items:center;">-->
<!--                            <label class="label" style="margin-bottom:0;">{{$t('settings["Номер телефона"]')}}</label>-->
<!--                            <div class="control" style="display: flex;align-items:center;">-->
<!--                                <span class="settings__contact" v-if="profile.phone" style="display:block;margin:0 15px 0 0;">{{profile.phone}}</span>-->
<!--                                <span class="settings__contact" v-else>{{$t('settings["Не указан"]')}}</span>-->
<!--                                <v-btn class="btn_text" text small color="primary" @click="$refs.PopupPhone.open()">-->
<!--                                   <template v-if="profile.phone">{{$t('settings["Изменить"]')}}</template>-->
<!--                                    <template v-else>{{$t('settings["Добавить"]')}}</template>-->
<!--                                </v-btn>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="field" style="align-items:center;">-->
<!--                            <label class="label" style="margin-bottom:0;">{{$t('settings["E-mail адрес"]')}}</label>-->
<!--                            <div class="control" style="display: flex;align-items:center;">-->
<!--                                <span class="settings__contact" v-if="profile.email" style="display:block;margin:0 15px 0 0;">{{profile.email}}</span>-->
<!--                                <span class="settings__contact" v-else>{{$t('settings["Не указан"]')}}</span>-->
<!--                                <v-btn class="btn_text" text small color="primary" @click="$refs.PopupEmail.open()">-->
<!--                                    <template v-if="profile.email">{{$t('settings["Изменить"]')}}</template>-->
<!--                                    <template v-else>{{$t('settings["Добавить"]')}}</template>-->
<!--                                </v-btn>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                  <div class="field" style="width: 100%;">
                    <div class="control" style="margin: 0;display:flex;justify-content: flex-end;width: 100%;">
                      <v-btn class="settings__submit" outlined dense small color="primary" @click="saveProfile">
                        {{$t('settings["Cохранить"]')}}
                      </v-btn>
                    </div>
                  </div>
                    <div class="field" style="align-items: center;">
                      <label class="label">Want to change your password?</label>
                        <div class="control">
                            <v-btn class="btn_text" text small color="primary" @click="$refs.PopupPassword.open()">
                                {{$t('settings["Изменить пароль"]')}}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="settings__user">
                <img ref="avatar-image" class="settings__avatar" :src="profile.avatar ? `${VUE_APP_STORAGE_URL+profile.avatar}` : require('@/assets/icons/user_placeholder.svg')" alt="">
                <h3 class="settings__name title">{{profile.name}}</h3>
                <input ref="avatar-file" class="avatar-upload__input" type="file" accept=".jpg, .jpeg, .png" >
                <v-btn class="btn_text" text small color="primary" @click="avatarUpload">{{$t('settings["Изменить аватар"]')}}</v-btn>
            </div>
        </div>
        <div style="display: flex;flex-direction: column;margin-bottom: 50px;" v-else>
          <div class="settings__user" style="width: 100%;margin:0 0 15px 0;">
            <img ref="avatar-image" class="settings__avatar" :src="profile.avatar ? `${VUE_APP_STORAGE_URL+profile.avatar}` : require('@/assets/icons/user_placeholder.svg')" alt="">
            <h3 class="settings__name title">{{profile.name}}</h3>
            <input ref="avatar-file" class="avatar-upload__input" type="file" accept=".jpg, .jpeg, .png" >
            <v-btn class="btn_text" text small color="primary" @click="avatarUpload">{{$t('settings["Изменить аватар"]')}}</v-btn>
          </div>
          <div class="settings__info"  style="width: 100%;margin:0 0 15px 0;">
            <div class="form form_row">
              <div class="field">
                <label class="label" style="width: 150px">Name</label>
                <div class="control" style="width: calc(100% - 150px);">
                  <v-text-field solo outlined flat class="large_50"
                                :label="$t('app[\'Ввести\']')"
                                autocomplete="new-password"
                                :rules="nameRules"
                                v-model="profile.name"
                  ></v-text-field>
                </div>
              </div>
              <div class="field">
                <label class="label" style="width: 150px">Last Name</label>
                <div class="control" style="width: calc(100% - 150px);">
                  <v-text-field solo outlined flat
                                label="Last Name" class="large_50"
                                autocomplete="new-password"
                                :rules="lastNameRules"
                                v-model="profile.last_name"
                  ></v-text-field>
                </div>
              </div>
              <div class="field">
                <label class="label" style="width: 150px">Phone</label>
                <div class="control" style="width: calc(100% - 150px);">
                  <v-text-field solo outlined flat class="large_50"
                                v-model="profile.phone"
                  ></v-text-field>
                </div>
              </div>
              <div class="field">
                <label class="label" style="width: 150px">Email</label>
                <div class="control" style="width: calc(100% - 150px);">
                  <v-text-field solo outlined flat class="large_50"
                                :rules="emailRules"
                                v-model="profile.email"
                  ></v-text-field>
                </div>
              </div>
              <div class="field" style="width: 100%;">
                <div class="control" style="margin: 0;display:flex;justify-content: flex-end;width: 100%;">
                  <v-btn class="settings__submit" outlined dense small color="primary" @click="saveProfile">
                    {{$t('settings["Cохранить"]')}}
                  </v-btn>
                </div>
              </div>
              <div class="field" style="align-items: center;">
                <label class="label" style="width: 150px">Want to change your password?</label>
                <div class="control" style="width: calc(100% - 150px);">
                  <v-btn class="btn_text" text small color="primary" @click="$refs.PopupPassword.open()">
                    {{$t('settings["Изменить пароль"]')}}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupPhone ref="PopupPhone" :phone_current="profile.phone" />
        <PopupEmail ref="PopupEmail" :currentEmail="profile.email" />
        <PopupPassword ref="PopupPassword" @successPassword="$refs.PopupSuccess.open()" />
        <PopupSuccess ref="PopupSuccess" title="Success!" :content="$t('popup[\'Вы успешно сменили пароль\']')"/>
    </main>
</template>

<script>
import store from '@/store'
// import { mapState } from 'vuex';
import titleMixin from '@/mixins/titleMixin'

import PopupPhone from '@/components/PopupPhone.vue';
import PopupEmail from '@/components/PopupEmail.vue';
import PopupPassword from '@/components/PopupPassword.vue';
import PopupSuccess from '@/components/Popup/PopupSuccess.vue';

export default {
    name: 'Settings',
    title() {
        return `${this.title}`
    },
    mixins: [titleMixin],
    components: { PopupPhone, PopupEmail, PopupPassword, PopupSuccess },
    beforeRouteEnter (to, from, next) {
        store.dispatch('getProfile')
            .then(() => {
                next();
            }).catch(() => {
                next();
            })
    },
    data() {
        return {
          isMobile:false,
            title: 'Profile',
          phoneRules: [
            v => !!v || this.$t('stickers["Поле обязательно"]'),
          ],
            emailRules: [
              (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
              (v) =>
                /.+@.+\..+/.test(v) ||
                this.$t('landing.valid["E-mail должен быть валидным"]'),
            ],
            nameRules: [
                v => !!v || this.$t('landing.valid["Имя обязателено"]'),
            ],
            lastNameRules:[
                v => !!v || 'Last name is required',
            ]
        }
    },
    computed: {
        // ...mapState(['profile'])
        profile() {
            return JSON.parse(JSON.stringify(this.$store.state.profile));
        },
      VUE_APP_STORAGE_URL(){
          return process.env.VUE_APP_STORAGE_URL
}
    },
    methods: {
        // changeAvatar() {
        //     const self = this;
        //     this.$refs.file.click();
        //     this.$refs.file.addEventListener('change', function() {
        //         self.readURL(this);
        //     });
        // },
        // readURL(input) {
        //     const self = this;
        //     if (input.files && input.files[0]) {
        //         const reader = new FileReader();
        //         reader.onload = function (event) {
        //             self.$refs.avatar.src = event.target.result;
        //             // img.style.background = "#fff";
        //         }
        //         reader.readAsDataURL(input.files[0]);
        //     }
        // },
        async logout() {
            this.$store.dispatch('logout');
            await this.$router.push('/')
        },
        saveProfile() {
            this.$store.dispatch('saveProfile', {
                name: this.profile.name,
                last_name: this.profile.last_name,
                phone: this.profile.phone,
                email: this.profile.email,
                // avatar: null,
            })
        },
        avatarUpload() {
            let self = this;
            let inputFile = this.$refs['avatar-file'];
            let img = this.$refs['avatar-image'];

            inputFile.click();

            inputFile.addEventListener('change', function() {
                self.readURL(this, img);
                self.$store.dispatch('saveProfile', {
                    avatar: this.files[0],
                });
            });
        },

        readURL(input, img) {
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.addEventListener('load', (e) => {
                    img.src = e.target.result;
                })
                reader.readAsDataURL(input.files[0]);
            }
        },

        successPassword() {

        }
    },
    created() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
};
</script>

<style lang="scss">
.settings {
    @include row;
    align-items: flex-start;
}

.settings__info {
    @include col(8);
    @include box;
    padding: 30px;
}

.settings__user {
    @include col(4);
    @include box;
    text-align: center;
    padding: 50px 30px 40px;
}

.settings__title {
    @include subtitle;
}

.settings__submit {
    max-width: 210px;
    width: 100%;
}

.settings__contacts {
    padding: 30px 0;
    .field {
        margin-bottom: 30px;
    }
    .label {
        padding-top: 0;
    }
}

.settings__contact {
    @include title;
    display: block;
    margin-bottom: 10px;
}

.settings__avatar {
    width: 160px;
    height: 160px !important;
    border-radius: 50%;
    margin-bottom: 30px;
    object-fit: cover;
}

.settings__name {
    margin-bottom: 10px;
}

.avatar-upload__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}
  @media(min-width: 1264px){
    .large_50{
      width:50%;
    }
  }
</style>
