<template>
    <v-dialog max-width="410px" v-model="dialog">
        <div class="popup__content">
            <v-btn class="popup__close" small icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <!-- <h5 class="popup__title" v-if="currentEmail">Редактировать e-mail <br> {{currentEmail}}</h5> -->
            <h5 class="popup__title" v-if="currentEmail">{{$t('settings["Редактировать e-mail"]')}}</h5>
            <h5 class="popup__title" v-else>{{$t('settings["Добавить e-mail"]')}}</h5>
            <v-form class="form"
                ref="form"
                @submit="submit" 
            >
                <div class="field">
                    <div class="control">
                        <v-text-field solo outlined flat
                            type="email"
                            :label="$t('form[\'E-mail\']')"
                            :rules="emailRules"
                            v-model="email"
                            validate-on-blur
                            :error-messages="error"
                            :error="!!error"
                        ></v-text-field>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <v-btn color="primary" block depressed type="submit">
                            {{$t('settings["Cохранить"]')}}
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        currentEmail: String
    },
    data() {
        return {
            dialog: false,
            email: null,
            emailRules: [
                v => !!v || this.$t('landing.valid["E-mail обязателен"]'),
                v => /.+@.+\..+/.test(v) || this.$t('landing.valid["E-mail должен быть валидным"]'),
            ],
            error: '',
        }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        submit(e) {
            e.preventDefault();
            
            if (this.$refs.form.validate()) {
                this.$store
                    .dispatch('saveProfile', {email: this.email})
                    .then(response => {
                        if (response === 'this email is already in use') {
                            this.error = this.$t("popup['this email is already in use']");
                        } else {
                            this.close();
                        }
                    })
            }
        }
    }
}
</script>

<style lang="scss">

</style>
